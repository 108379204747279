export interface SymptomsData {
  fever: boolean;
  respiratorySigns: boolean;
  headaches: boolean;
  lossOfSmell: boolean;
}

export class Symptoms implements SymptomsData {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public fever: boolean,
    public respiratorySigns: boolean,
    public headaches: boolean,
    public lossOfSmell: boolean
  ) {}

  public set (property: keyof SymptomsData, value: boolean): void {
    this[property] = value
  }

  public selected (): Array<keyof SymptomsData> {
    const result = new Array<keyof SymptomsData>()

    this.addIfSelected('fever', result)
    this.addIfSelected('respiratorySigns', result)
    this.addIfSelected('headaches', result)
    this.addIfSelected('lossOfSmell', result)

    return result
  }

  private addIfSelected (symptom: keyof SymptomsData, selected: Array<keyof SymptomsData>): void {
    if (!this[symptom]) {
      return
    }

    selected.push(symptom)
  }

  public static empty (): Symptoms {
    return new Symptoms(
      false,
      false,
      false,
      false
    )
  }

  public any (): boolean {
    return this.fever ||
      this.respiratorySigns ||
      this.headaches ||
      this.lossOfSmell
  }

  public none (): boolean {
    return !this.any()
  }
}
