










































import { Component, Vue, Watch } from 'vue-property-decorator'
import Player from '@/components/common/Player.vue'
import HighlightText from '@/components/HighlightText.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import { RawLocation } from 'vue-router'
@Component({
  components: {
    Player,
    HighlightText,
    ContentArea,
    Title,
    ProgressLine
  }
})
export default class Step2 extends Vue {
 private isText = false
 private path = 0

 private videoIsEnd (): void {
   this.isText = true
 }

 private get continueButtonTextKey (): string {
   return this.isText
     ? 'quidel.common.question_answer_button.text'
     : 'quidel.step_2.continue_button.text'
 }

 private goTo (path: RawLocation): void {
   this.$router.push(path)
 }

 private mounted (): void {
   if (window.innerWidth < 768) {
     this.$root.$on('mobile-go-back', () => {
       this.goBack()
     })
   }
 }

 get videoUrls (): Array<string> {
   return this.$store.state.video.videoUrls[3]
 }

 //  get videoUrls (): any {
 //    const startEend = process.env.VUE_APP_VIDEO_START_END.split('/')[3]
 //    return 'https://res.cloudinary.com/hey-expert/video/upload/v1631162783/BellaHealth/Oman/EN_2/English_w_English_Subtitles_from_Babylon_tcxvs2.mp4#t=' + startEend
 //  }

 private goBack (): void {
   this.$router.push({ name: 'walkthrough 1' })
 }

 private destroyed (): void {
   if (window.innerWidth < 768) this.$root.$off('mobile-go-back')
 }
}
